import styled from "styled-components"
export const CompanyWrapper = styled.div`

.avatar {
  img { border-radius: 50%; }
}
  .row > {
    div {
      margin: 0;
      padding: 0;
    }
  }
  .company-title {
    font-size: 37px;
    margin-bottom: 20px;
  }
  .company-subtitle,
  .exp-subtitle {
    font-size: 20px;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    line-height: inherit;
  }
  .company-content,
  .exp-content {
    font-size: 20px;
    font-weight: 200;
    margin: 0;
    padding-bottom: 25px;
    span {
      font-weight: bold;
    }
  }

  .about-container {
    margin-bottom: 50px;
    padding-top: 0;
  }
  .team-container {
    padding: 100px 0 100px 0;
    background-color: #f8f8f8;
  }
  .row .top-person {
    margin-top: 12px;
  }
  .person-section {
  padding-top: 60px!important;
  }
  .person-item {
    display: flex;
    justify-content: left;
    .content,
    img {
      margin-bottom: 25px;
    }
    img {
      width: 80px;
      height: 80px;
    }
    .content {
      padding: 0 26px;
      display: flex;
      align-items: center;
      h4 {
        font-size: 20px;
        margin: 0;
      }
      p {
        margin: 0;
        font-size: 20px;
        font-weight: 200;
      }
    }
  }
  .row {
    .buttons-wrapper {
      display: flex;
      justify-content: space-between;
      margin: 0 0 50px;
      padding-top: 25px;
      .write {
        padding: 0 5px 0 0;
      }
      .call {
        padding: 0 0 0 5px;
      }
    }
    .large-buttons {
      flex-wrap: wrap;
      margin-bottom: 65px;
      .button-item {
        margin-bottom: 15px;
      }
    }
  }
  .exp-container {
    background-color: white;
    margin: 45px auto;
  }
  .nat-container {
  }
  @media (min-width: 1200px) {
    padding-top: 59px;
    .company-title {
      font-size: 48px;
      margin-bottom: 40px;
    }
    .company-subtitle {
      width: 80%;
      margin-bottom: 25px;
    }
    .about-container {
    padding-bottom: 50px;
    }
    .exp-container {
    }
    .buttons-desktop {
      padding-top: 110px;
      a:first-child {
        margin-right: 14px;
      }
    }
    .person-item {
    margin-bottom: 34px!important;
      .content {
        margin: 0;
      }
    }
    .avatar {
    display: flex;
    align-items: center;
    
     
      img {
        //height: 100%;
        margin:0;
        border-radius: 50%;
      }
    }
    
    .team-content {
      width: 73%;
    }
    .team-container {
      border-bottom: 1px solid #e3e3e3;
    }
    .row {
      overflow: hidden !important;
    }
    .green-line {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        background-color: #94c835;
      }
    }
    .submenu-wrapper {
      padding: 0 0 0 0 !important;
      display: flex;
      align-items: center;
    }
    .company-image {
      justify-content: center;
      padding-top: 40px !important;
      img {
        height: 80%;
        z-index: 9999;
      }
    }
    @media (min-width: 1440px) {
    .buttons-desktop {
      padding-top: 90px;
     
    }
      .green-line {
        &:after {
          bottom: 0;
        }
      }
      .company-image {
        img {
          margin: 0;
          height: 100%;
        }
      }
    }
    .person-section {
      padding-top: 75px!important;
    }
  }
`
