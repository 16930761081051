import React from "react"
import Layout from "../../components/layout"
import CompanyContainer from "../../components/company/company"
import Helmet from "react-helmet"

const Company = () => (
  <>
    <Helmet>
      <title>WILLBUD | Firma</title>
    </Helmet>
    <Layout>
      <CompanyContainer />
    </Layout>
  </>
)

export default Company
